<template>
  <div class="w1092 pt60">
    <div class="board">
      <div class="boardrd first">
        <span class="nicon ignbg ml10">일반</span>
        <span class="ml10"># 충전 및 환전 안내사항 #</span>
      </div>
      <div class="boardrd">
        <span>win</span><span class="ml10">2025.04.02 14:23</span>
      </div>
      <div class="boardbox">
        <pre>
-충전 신청시 고객센터 문의후 입금해 주시기바랍니다.
계좌가 수시로 변경되오니 불편하더라고 꼭 문의후 입금바랍니다. 계좌 변경으로 인한 잘못된 입금은
책임지지 않으니 필히 문의후 입금바랍니다.

-충전 신청시 만원단위로 입금 가능하며 최대 9백만까지 가능합니다

-하루 최대 출금액은 6천만원으로 제한합니다

-1회 최대 출금액은 2천만원 까지이며 재환전 출금시간은 직전 환전후 1시간 이후 가능합니다.

-은행점검 시간은 23:30~12:30 까지는 환전을 제한합니다

-환전은 롤링 100%후 환전이 가능합니다
        </pre>
      </div>
    </div>

    <div class="boardbtn">
      <!-- <a class="blsbtn" v-if="prevId" @click="onClickPrevNext('prev')">{{$t('front.board.prev')}}</a> -->
      <a class="blsbtn" @click="goPageByName('noticeList')">{{$t('front.board.list')}}</a>
      <!-- <a class="blsbtn" v-if="nextId" @click="onClickPrevNext('next')">{{$t('front.board.next')}}</a> -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'read4',
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
